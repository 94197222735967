<template>
  <div class="container">
    <p class="msg">
      <van-icon
        size="25"
        @click="fh"
        style="transform: translateY(10px)"
        name="arrow-left"
      />
      <span class="wan">工作履历</span>
      <!-- <span class="span" style="margin-left: 2rem" @click="bc">保存</span> -->
    </p>
    <div class="inresume">
      <p>
        <span class="ss">企业名称</span
        ><input
          v-model="nameoffirm"
          @blur="blur1"
          type="text"
          placeholder="请输入企业名称"
          style="text-align: right"
        />
      </p>
      <p>
        <span class="ss" @click="show = true">入职时间</span
        ><input
          readonly="readonly"
          type="text"
          placeholder="请选择入职时间"
          v-model="entry"
          @click="show = true"
          style="text-align: right"
        />
      </p>
      <p>
        <span class="ss" @click="show1 = true">离职时间</span
        ><input
          readonly="readonly"
          type="text"
          placeholder="请选择离职时间"
          v-model="finish"
          @click="show1 = true"
          style="text-align: right"
        />
      </p>
      <p>
        <span class="ss" @click="zw">工作职位</span
        ><input
          readonly="readonly"
          :disabled="$route.params.id != null"
          @click="zw"
          type="text"
          v-model="jobposition"
          placeholder="请选择工作"
          style="text-align: right"
        />
      </p>
      <p style="border-bottom: 0px none">
        <span class="ss">工作经验</span>
      </p>

      <textarea
        name=""
        placeholder="完善的履历更容易获得优质企业关注哦！"
        v-model="jobresume"
        id=""
      ></textarea>

      <p style="border-bottom: 0px none">
        <span class="ss">工作职责</span>
      </p>

      <textarea
        name=""
        placeholder="完善的履历更容易获得优质企业关注哦！"
        v-model="jobzz"
        id=""
      ></textarea>

      <div>
        <div class="btn4"  @click="bc" id="triggerBtn">确认</div>
        <div class="goback" v-if="type == 1" @click="del" id="triggerBtn">删除</div>
      </div>
      <!-- <p style="border-bottom: 0px none">
        <span>本人职责</span>
      </p>
      <textarea
        name=""
        placeholder="填写工作履历"
        v-model="from[0].responsibilities"
        id=""
      ></textarea> -->
      <!-- <div @click="del" class="sc" v-if="$route.params.id">删除</div> -->
      <!-- <div @click="bc" class="sc" v-if="!$route.params.id">保存</div> -->
    </div>
    <van-action-sheet v-model="show" title="">
      <div class="content">
        <van-datetime-picker
          v-model="currentDate"
          type="year-month"
          title="选择年月"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="qd"
          @cancel="show = false"
        />
      </div>
    </van-action-sheet>
    <van-action-sheet v-model="show1" title="">
      <div class="content">
        <van-datetime-picker
          v-model="currentDate1"
          type="year-month"
          title="选择年月"
          :min-date="minDate1"
          :max-date="maxDate1"
          @confirm="qd1"
          @cancel="show1 = false"
        />
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { Toast } from 'vant';
import getDate from "../../util/dateChange"
export default {
  data() {
    return {
      show: false,
      show1: false,
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
      minDate1: new Date(2000, 0, 1),
      maxDate1: new Date(),
      currentDate1: new Date(),
      type:"",
      id: Date.parse(new Date()),
      nameoffirm: "", //企业名称
      entry: "", //入职
      finish: "", //离职
      jobposition: "", //工作职位
      jobresume: "", //工作经验
      jobzz:"" ,  //工作职责
      responsibilities: "", //
      rzhm:"", // 入职毫秒数
      lzhm:"", //  离职毫秒数
      
    };
  },
  mounted(){
    if(sessionStorage.getItem("comname")){
      this.nameoffirm = sessionStorage.getItem("comname")
    }
    if(sessionStorage.getItem("entryTime")){
       this.entry = sessionStorage.getItem("entryTime")
    }
     if(sessionStorage.getItem("finish")){
       this.finish = sessionStorage.getItem("finish")
    }

    
    let type1 = sessionStorage.getItem("type1")
    this.type = type1
    if(sessionStorage.getItem("item1") && type1 == 1){
      let {company_name, entry_time, my_duty, position, quit_time, working_experience} =JSON.parse(sessionStorage.getItem("item1")) 
      this.nameoffirm = company_name
      this.entry = entry_time
      this.finish = quit_time
      this.jobzz = my_duty
      this.jobposition = position
      this.jobresume = working_experience
    }else{
      this.jobposition = sessionStorage.getItem("item2");
    }
    if(this.$route.query.jobposition){
      this.jobposition = this.$route.query.jobposition
    }
   
  },
  methods: {
    blur1(){
      sessionStorage.setItem("comname",this.nameoffirm)  
    },
    //保存履历
    bc() {
      if(sessionStorage.getItem("type1") == 0){
         if(this.nameoffirm == ''){
        Toast("请输入企业名称")
        return 
        }
        if(this.entry == ""){
          Toast("请选择入职时间")
          return
        }
        if(this.finish == ""){
          Toast("请选择离职时间")
          return
        }
        if(this.jobposition == ""){
          Toast("请选择工作职位")
          return
        }
        if(this.jobresume == ""){
          Toast("请输入工作经验")
          return
        }
        if(this.jobzz == ""){
          Toast("请输入工作职责")
          return
        }

        let workArrPosition = sessionStorage.getItem("workArrPosition")
        // console.log(workArrPosition)
        let arr = JSON.parse(sessionStorage.getItem("work_resume"))
      
        let obj = {}
        obj.company_name = this.nameoffirm
        sessionStorage.setItem("nameoffirm", this.nameoffirm)

        obj.entry_time = this.entry
        sessionStorage.setItem("entry", this.entry)

        obj.quit_time = this.finish
        sessionStorage.setItem("finish", this.finish)

        obj.position = workArrPosition
        sessionStorage.setItem("workArrPosition", this.workArrPosition)

        obj.working_experience = this.jobresume
        sessionStorage.setItem("jobresume", this.jobresume)

        obj.my_duty = this.jobzz
        sessionStorage.setItem("jobzz", this.jobzz)
        arr.push(obj)
        arr = JSON.stringify(arr)
        // console.log(arr)
        sessionStorage.setItem("work_resume", arr)


        this.$router.push("./resume2")
      }else{
        let work_resume = JSON.parse(sessionStorage.getItem("work_resume")) 
        let item = JSON.parse(sessionStorage.getItem("item1"))
        work_resume.forEach(element=>{
          if(element.id == item.id){
             element.company_name = this.nameoffirm
            element.entry_time = this.entry
            element.my_duty = this.jobzz
            element.position = this.jobposition
            element.quit_time = this.finish
            element.working_experience = this.jobresume
          }
          return element
        })
        sessionStorage.setItem("work_resume",JSON.stringify(work_resume)) 
        this.$router.push('./resume2')
        // console.log(work_resume)
      }
     
      
    },
    //返回过往履历页面
    fh() {
      sessionStorage.removeItem("wz");
      this.$router.push("/resume2");
    },
    //删除本条履历
    del() {
      // console.log(1)
       let arr = JSON.parse(sessionStorage.getItem("work_resume")) 
      let item =  JSON.parse(sessionStorage.getItem("item1"))
      arr.forEach(function(item1,index){
        if(item1.id == item.id){
          // console.log(index)
          arr.splice(index,1)
          Toast("删除成功")
          
        }
      });
      this.$router.push("./resume2")
       sessionStorage.setItem("work_resume",JSON.stringify(arr))

      // let arr = JSON.parse(sessionStorage.getItem("list"));
      // sessionStorage.removeItem("wz");
      // arr = arr.filter((item) => {
      //   return item.id != this.$route.params.id;
      // });
      // arr = JSON.stringify(arr);
      // console.log(arr);
      // sessionStorage.setItem("list", arr);
      // this.$router.push("/resume2");
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    //获取入职时间
    qd(val) {
      let aa = new Date(val);
       let aa1 = getDate(aa,1)
      let rzhm = aa.getTime()
      this.rzhm = rzhm
      this.entry = aa1
      sessionStorage.setItem("entryTime",this.entry)
      this.show = false;
    },
    //获取离职时间
    qd1(val) {
      
      let aa = new Date(val);
      let aa1 = getDate(aa,1)
      let lzhm = aa.getTime()
      // console.log(lzhm)
      if(this.rzhm > lzhm){
        Toast("离职时间不能小于入职时间")
        return
      }
      this.finish = aa1
      sessionStorage.setItem("finish",this.finish)
      this.show1 = false;
    },
    //选择工作岗位缓存本条履历
    zw() {
      this.$router.push({path:"./station",query:{type:"workArrPosition"}})
      // localStorage.setItem("wz", JSON.stringify(this.from));
      // this.$router.push({
      //   name: "zw",
      // });
    },
  },
};
</script>

<style scoped >
.goback{
  width: 6.86rem;
  height: 0.96rem;
  border-radius: 0.48rem;
  font-size: 0.36rem;
  line-height: 0.97rem;
  background: #F7F7F7;
  border: 0.02rem solid #FF5817;
  color:#FF5817;
  margin:0 auto 0.2rem;
  
  text-align: center;
}
.btn4 {
  width: 6.86rem;
  height: 0.96rem;
  background: linear-gradient(90deg, #f55613 0%, #fc9e46 100%);
  border-radius: 0.48rem;
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 0.96rem;
  margin-top: 1.38rem;
  /* margin-left: 0.34rem;
  margin-bottom: 0.1rem; */
 margin:0.4rem auto;
  
}
.container{
  position: fixed;
  height:100%;
  width:100%;
  background-color: #fff;
  top: 0;
  bottom:0;
  overflow-y:scroll;
  overflow-x:hidden;
}
.ss::before {
  content: "*";
  color: #ff0000;
  font-size: 0.25rem;
  position: relative;
  margin-right: 0.1rem;
  /* top: 0.1rem; */
}
input:disabled {
  background-color: #fff;
}

.sc {
  width: 6.86rem;
  height: 0.96rem;
  line-height: 0.96rem;
  text-align: center;
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  background: linear-gradient(90deg, #f55613 0%, #fc9e46 100%);
  border-radius: 0.48rem;
}
.msg {
  line-height: 0.88rem;
  border-bottom: solid 0.01rem #eeeeee;
}

.msg .wan {
  margin-left: 2.7rem;
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
}
.inresume {
  padding: 0 0.2rem;
}
.inresume textarea {
    resize: none;
    outline: none;
    font-size: 0.3rem;
    width: 100%;
    height: 4rem;
    border: 1px solid #ccc;
    padding: 10px;
    box-sizing: border-box;
  }
.inresume p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1.46rem;
    border-bottom: 1px solid #ccc;
  }
.inresume p span {
  font-size: 0.3rem;
  width: 1.5rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #697386;
}
.inresume p input {
  outline: none;
  border: 0 none;
  font-size: 0.3rem;
  width: 5rem;
}
.span {
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
}
</style>